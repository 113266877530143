<template>
  <div class="footer">
    <div class="footer-inner">
      <div class="logo-holder">
        <a target="_blank" rel="noopener" href="https://api.whatsapp.com/send?phone=6585712472"><font-awesome-icon :icon="['fab', 'whatsapp']"></font-awesome-icon></a>
        <a target="_blank" rel="noopener" href="https://www.linkedin.com/in/kc-koh-7b8094222/"><font-awesome-icon :icon="['fab', 'linkedin-in']"></font-awesome-icon></a>
        <a rel="noopener" href="mailto:x3iidestiny@hotmail.sg"><font-awesome-icon :icon="['fas', 'envelope']"></font-awesome-icon></a>
      </div>
      <span class="nowrap">x3iidestiny©{{year}}</span>

      <a class="scroll-to-top" href="#"></a>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, computed, watch } from 'vue';

export default {
  setup() {
    const year = computed(() => new Date().getFullYear())
    return { year }
  }
  
}
</script>
import router from '@/router';

export const account = {
    namespaced: true,
    state: {mode: 'light'},
    getters: {
      isLoggedIn: state => {
        return state.user != null;
      }, 
    },
    actions: {
        setDark({ dispatch, commit }) {
          commit('setToDark'); 
        },
        setLight({ dispatch, commit }) {
          commit('setToLight');
        },
    },
    mutations: {
        setToDark(state) {
          state.mode = 'dark';
        },
        setToLight(state) {
          state.mode = 'light';  
        },
    }
}

export default {
  setupMenu: ()=>{
    $(window).resize(function(){
      if($(window).width() > 880){
        if(!$(".lightbox").hasClass('show')){
          $("body").removeClass("noscroll");
        }
				if($(".landing-menu").hasClass("show")){
					$(".landing-menu").removeClass("show");
          $(".landing-menu").addClass("hide");
				}
      }
      // for lightbox
      // if($(".lightbox").hasClass('show')){
			// 	if($(window).width() > $(window).height()) {
			// 		$(".prompt-rotate").removeClass('show');
			// 		$(".prompt-rotate").addClass('hide');
			// 	} else {
			// 		$(".prompt-rotate").removeClass('hide');
			// 		$(".prompt-rotate").addClass('show');
			// 	}
      // }
      // for lightbox
    });
  },
  resetPage: ()=>{
    $("body").removeClass("noscroll");
    if($(".landing-menu").hasClass("show")){
      $(".landing-menu").removeClass("show");
      $(".landing-menu").addClass("hide");
    }
  },
  toggleLightbox : (item)=>{
    // prompt-rotate
    // if($(window).width() < $(window).height()) {
		// 	$(".prompt-rotate").removeClass('hide');
		// 	$(".prompt-rotate").addClass('show');
    // }
    // prompt
    $(".lightbox").removeClass('hide');
    $(".lightbox").addClass('show');
    $("body").addClass("noscroll");
    $('[data-link]').show();

    if (item){
      $('[data-link]').attr('href', item);
      $('[data-link]').text(item);
    } else {
      $('[data-link]').hide();
    }
    // $(".video-frame").attr('src', item);
  },
  setupScrolltop: ()=>{
    // scrolltop functions
    $('.scroll-to-top').on("click", function(e) {
      e.preventDefault();
      // window.scrollTo({top: 0, behavior: 'smooth'});
      $('html, body').animate({
        scrollTop: 0
      }, 500);
    });

    if( $(window).scrollTop() < $(".first-container").innerHeight() || $(".first-container").innerHeight() == null ){
      $("a.scroll-to-top").hide();
    } else { $("a.scroll-to-top").show(); }

    $(window).on("scroll", function() {
      setButtonPos();
    });

    $(window).on("resize", function(){
      setButtonPos();
    });

    function setButtonPos() {
      var right = $(window).width() > 600 ? 30 : 15;
      if( $(window).scrollTop() < $(".first-container").innerHeight() ){
        $("a.scroll-to-top").hide();
      } else { $("a.scroll-to-top").show(); }

      if($(window).scrollTop() + $(window).height() < $(document).height() - $(".footer").height()){
        $('a.scroll-to-top').css("position","fixed");
        $('a.scroll-to-top').css("bottom","15px"); 
        var r = ($(document).width()-1440)/2;
        r = r+right;
        if(r < right){ r = right; }
        $('a.scroll-to-top').css("right", r +"px"); 
      } else {
        $('a.scroll-to-top').css("position","absolute");
        var btm = $(".footer").height() - 24;
        $('a.scroll-to-top').css("bottom", btm + "px"); 
        $('a.scroll-to-top').css("right", right + "px");
      }
    }
  }
};


export default {
  "Decathlon": {
    "title": "Decathlon Careers 2022",
    "client": "Decathlon SG",
    "tech": "Javascript, TailwindCSS, PUG",
    "external_link": "http://decathlon-careers-html.s3-website-ap-southeast-1.amazonaws.com/",
    "showcase": [
      {content: '<div class="row align-items-center" animate-stagger><div class="col-lg-7 ml-auto order-lg-last" animate-item><img class="w-100 img-fluid" src=' + require("../assets/decathlon2.png") +'></img></div><div class="col-lg-4 order-lg-first" animate-item><span class="text-img-title">Staff Testimonials</span><span>To show the various testimonies of the staffs that are working in Decathlon. The team has come up with a unique carousel using slickJs.</span></div></div>'},
      {content: '<div class="row" animate-me><video class="detail-video" autoplay muted loop><source src=' + require("../assets/decath_vid.mp4") +'></video></div>'},
      {content: '<div class="row align-items-center" animate-stagger><div class="col-lg-7" animate-item><img class="w-100 img-fluid" src=' + require("../assets/decathlon4.png") +'></img></div><div class="col-lg-4 ml-auto" animate-item><span class="text-img-title">Tailwind css grid</span><span>Using the grid system of tailwind css to showcase statistics.</span></div></div>'},
    ],
    "gallery": [
      {id: 1, src: require("../assets/decathlon_gallery1.png")},
      {id: 1, src: require("../assets/decathlon_gallery2.png")},
    ],
    "thumbnail": require("../assets/decathlon1.png"),
    "path": '/Project?title=Decathlon',
    "body": "My team was tasked by the Decathlon team in Singapore to create a microsite for their careers page. This site showcases what working at Decathlon would be in terms of experience and what they do.\n\nI had to work within the constraints of following Decathlon's Design Patterns and using their in-house css stylings.",
    "category": 'Website',
    "company": 'EIGHTFOUR Digital'
  },
  "Light-to-Night": {
    "title": "Light to Night 2021",
    "client": "National Gallery Singapore",
    "tech": "HTML + PHP, CSS & Sass, Javascript",
    "external_link": 'https://www.nationalgallery.sg/festivals/light-to-night-2021-in-progress/',
    "showcase": [
      {content: '<div class="row align-items-center" animate-stagger><div class="col-lg-5 ml-auto order-lg-last" animate-item><div class="row"><div class="col-6"><img class="w-100 img-fluid" src=' + require("../assets/ltn_light.png") +'></img></div><div class="col-6"><img class="w-100 img-fluid" src=' + require("../assets/ltn_dark.png") +'></img></div></div></div><div class="col-lg-4 order-lg-first" animate-item><span class="text-img-title">Light and Dark mode</span><span>The site has an automatic timer that detects the local time of the user visiting. When the time hits 19:00 hours and later, the dark mode will be turned on automatically to improve visibility of the sight.</span></div></div>'},
      {content: '<div class="row" animate-me><div class="col-lg-9 mx-auto text-lg-center"><video class="detail-video" autoplay muted loop><source src=' + require("../assets/ltn_vid.mp4") +'></video><span class="video-txt">The interactive map is the main attraction of the whole site. Displaying the 7 unique spots where programmes were held for the festival, the buildings lights up and animates when the user interacts with it.</span></div></div>'},
      {content: '<div class="row align-items-center" animate-stagger><div class="col-lg-7" animate-item><img class="w-100 img-fluid" src=' + require("../assets/ltn3.png") +'></img></div><div class="col-lg-4 ml-auto" animate-item><span class="text-img-title">QR & AR Experience</span><span>These are 6 stunning QR, AR experiences where users can scan the QR code to be directed to an AR environment to view the selected artwork in an AR environment.</span></div></div>'},
    ],
    "gallery": [
      {id: 1, src: require("../assets/ltn_gallery1.png")},
      {id: 2, src: require("../assets/ltn9.png")},
      {id: 3, src: require("../assets/ltn10.png")},
      {id: 4, src: require("../assets/ltn11.png")},
    ],
    "thumbnail": require("../assets/ltn0.png"),
    "path": '/Project?title=Light-to-Night',
    "body": "Light to Night 2021 is a website created for the Light to Night Festival as it had both physical and digital events going on at the same time.\n\nI took part in creating the microsite for the event, including an interactive map and interactive quizzes, to showcase the 5 Civic District partners and over 70 onsite and online programmes.",
    "category": 'Website',
    "company": 'IOIOlab'
  },
  "Coast-to-Coast": {
    "title": "NParks Explore A Route",
    "client": "National Parks(NParks)",
    "tech": "React Native, Firebase, MapboxGL, External API",
    "external_link": "https://coast-to-coast.nparks.gov.sg/",
    "showcase": [
      {content: '<div class="row align-items-center" animate-stagger><div class="col-lg-3 ml-auto order-lg-last" animate-item><img class="w-100 img-fluid" src=' + require("../assets/nparks1.jpg") +'></img></div><div class="col-lg-4 order-lg-first" animate-item><span class="text-img-title">Routing Navigation</span><span>Users of the app are able to find directions to a certain location/ point of interest within the Coast to coast route!</span></div></div>'}
    ],
    "gallery": [
      {id: 1, src: require("../assets/ss_nparks.jpg")}
    ],
    "thumbnail": require("../assets/nparks0.jpg"),
    "path": '/Project?title=Coast-to-Coast',
    "body": "This mobile application was a project from National Parks(NParks) Singapore. The purpose of this app is to let people explore the trails and routes that NParks offers and also know about the amenities/services within the routes.\n\nInteresting features of the app includes recording your own trail, getting directions to a point in the route and even AR experiences!",
    "category": 'Mobile App',
    "company": 'IOIOlab'
  },
  "Seek-Salary": {
    "title": "Seek Salary Calculator",
    "client": "Jobstreet Seek",
    "tech": "PUG, Javascript, CSS & Sass, Wordpress",
    "external_link": "https://www.jobstreet.com.sg/salary-guide",
    "showcase": [
      {content: '<div class="row align-items-center" animate-stagger><div class="col-lg-7 ml-auto order-lg-last" animate-item><img class="w-100 img-fluid" src=' + require("../assets/seek_salary2.png") +'></img></div><div class="col-lg-4 order-lg-first" animate-item><span class="text-img-title">Error handling</span><span>Checking of user input before doing all the API calls and services.</span></div></div>'},
      {content: '<div class="row align-items-center" animate-stagger><div class="col-lg-7" animate-item><img class="w-100 img-fluid" src=' + require("../assets/seek_salary3.png") +'></img></div><div class="col-lg-4 ml-auto" animate-item><span class="text-img-title">ChartJS</span><span>Reading the values from the API provided by the SEEK team, the data is then plotted into a line chart using ChartJS v3.</span></div></div>'},
    ],
    "gallery": [
      {id: 1, src: require("../assets/seek_salary_gallery1.png")},
      {id: 2, src: require("../assets/seek_salary_gallery2.png")},
    ],
    "thumbnail": require("../assets/seek_salary0.png"),
    "path": '/Project?title=Seek-Salary',
    "body": "Seek Salary Calculator is a one page html project with the purpose of improving the user's experience on using the salary calculator on SEEK's website. It involves reading the database and APIs from the SEEK team to display graphs and statistics for the user to visualize where they stand in the current industry market.",
    "category": 'Website',
    "company": 'EIGHTFOUR Digital'
  },
  "Creators-Academy": {
    "title": "ASUS Creators Academy",
    "client": "ASUS Singapore",
    "tech": "VueJS, CSS & Sass, Lottie",
    "external_link": "https://ioiolab.com/project/asus/dist/#/",
    "showcase": [
      {content: '<div class="row" animate-me><div class="col-lg-9 mx-auto text-lg-center"><video class="detail-video" autoplay muted loop><source src=' + require("../assets/asus_vid.mp4") +'></video><span class="video-txt">As users land on the home page they are greeted with a stunning animation done in lottie files.</span></div></div>'},
      {content: '<div class="row align-items-center" animate-stagger><div class="col-lg-7 ml-auto order-lg-last" animate-item><img class="w-100 img-fluid" src=' + require("../assets/asus2.jpg") +'></img></div><div class="col-lg-4 order-lg-first" animate-item><span class="text-img-title">Colorful backgrounds</span><span>The entire site is decorated with colorful patterns and gradients to make the background stand out from a normal website.</span></div></div>'},
    ],
    "gallery": [
      {id: 1, src: require("../assets/asus_gallery1.png")},
      {id: 2, src: require("../assets/asus_gallery2.jpg")},
      {id: 3, src: require("../assets/asus_gallery3.png")},
      {id: 4, src: require("../assets/asus_gallery4.png")},
    ],
    "thumbnail": require("../assets/asus1.png"),
    "path": '/Project?title=Creators-Academy',
    "body": "Creators Academy is a project from ASUS Singapore to showcase their content creator's works and also ASUS's products.\n\nIt allows visitors to the site to get the latest updates on tutorial videos from featured content creators and guides on how to create a stunning artwork.",
    "category": 'Website',
    "company": 'IOIOlab'
  },
  "Playpoint-Kinetics": {
    "title": "Playpoint - KINETICS",
    "client": "Playpoint Asia",
    "tech": "PUG, Javascript, CSS & Sass, Wordpress",
    "external_link": "https://stagingkineticsplay.playpoint.asia/",
    "showcase": [
      {content: '<div class="row" animate-me><div class="col-lg-9 mx-auto text-lg-center"><video class="detail-video" autoplay muted loop><source src=' + require("../assets/kinetics_vid.mp4") +'></video><span class="video-txt">Landing page attraction done with GSAP scrollTrigger.</span></div></div>'},
      {content: '<div class="row align-items-center" animate-stagger><div class="col-lg-7" animate-item><video class="detail-video" autoplay muted loop><source src=' + require("../assets/kinetics_vid1.mp4") +'></video></div><div class="col-lg-4 ml-auto" animate-item><span class="text-img-title">All about animations</span><span>Kinetics - the name suggests elegant and eye-catching movements. Therefore, the development team worked hard on each and every little animations found on the site.</span></div></div>'},
      {content: '<div class="row align-items-center" animate-stagger><div class="col-lg-7 ml-auto order-lg-last" animate-item><img class="w-100 img-fluid" src=' + require("../assets/kinetics2.png") +'></img></div><div class="col-lg-4 order-lg-first" animate-item><span class="text-img-title">Search bar</span><span>Users can use the search bar to search for the products or projects they were looking for.</span></div></div>'},
    ],
    "gallery": [
      {id: 1, src: require("../assets/kinetics_gallery1.png")}
    ],
    "thumbnail": require("../assets/kinetics1.webp"),
    "path": '/Project?title=Playpoint-Kinetics',
    "body": "Here we have a website project from Playpoint Asia showcasing the spectacular projects and products they have built all around the world.\n\n The site also gives an overview of what Playpoint Asia does, their motto and thinking processes.",
    "category": 'Website',
    "company": 'EIGHTFOUR Digital'
  },
  "InstaProtection": {
    "title": "InstaProtection",
    "client": "InstaProtection Pte Ltd",
    "tech": "React Native, Firebase, External API, Code Scanning",
    "external_link": "https://www.facebook.com/InstaProtectionSG/",
    "showcase": [
      {content: '<div class="row" animate-me><div class="col-lg-9 mx-auto text-center"><video class="detail-video" autoplay muted loop><source src=' + require("../assets/ip_vid.mp4") +'></video></div></div>'},
    ],
    "gallery": [
      {id: 1, src: require("../assets/ip0.jpg")}
    ],
    "thumbnail": require("../assets/ss_ip.jpg"),
    "path": '/Project?title=InstaProtection',
    "body": "InstaProtection is an application built for the company Instaprotection Pte Ltd. It allows users to buy or sell insurance plans for mobile devices, laptops and other electronic devices.\n\nThe app is connected with a CMS for admins to monitor transactions and review processes and distributing stocks to their dealers.",
    "category": 'Mobile App',
    "company": 'IOIOlab'
  },
  "Selling-Dreams": {
    "title": "Selling Dreams",
    "client": "National Library Board",
    "tech": "HTML, CSS & Sass, Javascript",
    "external_link": "https://ioiolab.com/project/nlb-selling-dreams/site/",
    "showcase": [
      {content: '<div class="row" animate-me><video class="detail-video" autoplay muted loop><source src=' + require("../assets/nlb_vid.mp4") +'></video></div>'},
    ],
    "gallery": [
      {id: 1, src: require("../assets/nlb1.png")},
    ],
    "thumbnail": require("../assets/nlb0.png"),
    "path": '/Project?title=Selling-Dreams',
    "body": "Selling Dreams is the 1st html project of my developer's career. This is a microsite built for National Library Singapore(NLB) for displaying information on their on-going event at the time after the physical event ends.\n\nIt also includes 3 mini-games coded as a replica to the onsite games that visitors could play.",
    "category": 'Website',
    "company": 'IOIOlab'
  },
  "Small-Big-Dreamers": {
    "title": "Small Big Dreamers At Home 2020",
    "client": "National Gallery Singapore",
    "tech": "HTML + PHP, Javascript, CSS & Sass",
    "external_link": "https://revez-motion.com/small-big-dreamers-2020-ngs/",
    "showcase": [
      {content: '<div class="row align-items-center" animate-stagger><div class="col-lg-7" animate-item><img class="w-100 img-fluid" src=' + require("../assets/sbd2.png") +'></img></div><div class="col-lg-4 ml-auto" animate-item><span class="text-img-title">CMS Integration</span><span>The site is integrated with a Content Management System for our client to create, read, update and delete programmes, artistes, and video contents.</span></div></div>'},
      {content: '<div class="row" animate-me><div class="col-lg-9 mx-auto text-lg-center"><video class="detail-video" autoplay muted loop><source src=' + require("../assets/sbd_vid.mp4") +'></video><span class="video-txt">Using html canvas and javascript manipulation, the team was able to achieve a storytelling like interaction as the user scrolls down the page.</span></div></div>'},
    ],
    "gallery": [
      {id: 1, src: require("../assets/sbd_gallery1.png")},
      {id: 2, src: require("../assets/sbd_gallery2.png")},
      {id: 3, src: require("../assets/sbd_gallery3.png")},
      {id: 4, src: require("../assets/sbd_gallery4.png")},
      {id: 5, src: require("../assets/sbd_gallery5.png")},
    ],
    "thumbnail": require("../assets/sbd0.png"),
    "path": '/Project?title=Small-Big-Dreamers',
    "body": "The Small Big Dreamers At Home microsite was built as an online exhibition to engage the young and small to try out the various activities that were on exhibited by the National Gallery of Singapore (NGS) in 2020.\n\nThe microsite included information for upcoming exhibitions/activities as well as games for the public to play.",
    "category": 'Website',
    "company": 'IOIOlab'
  },
  "Silver-Arts": {
    "title": "Silver Arts Exhibition 2021",
    "client": "National Arts Council Singapore",
    "tech": "HTML, Javascript, CSS & Sass",
    "external_link": "https://ioiolab.com/project/nac-silver-arts/site/index",
    "showcase": [
      {content: '<div class="row" animate-me><div class="col-lg-9 mx-auto text-lg-center"><video class="detail-video" autoplay muted loop><source src=' + require("../assets/nac_vid.mp4") +'></video><span class="video-txt">Various interesting contents like these are embedded to engage the user to participate.</span></div></div>'},
    ],
    "gallery": [
      {id: 1, src: require("../assets/nac_gallery1.png")}
    ],
    "thumbnail": require("../assets/nac0.png"),
    "path": '/Project?title=Silver-Arts',
    "body": "The Silver Arts Exhibition is a digital microsite built for the National Arts Council of Singapore, it aims to display the various artworks and programmes that were running and available during the Silver Arts Exhibition 2021.\n\nEvery year, Silver Arts commissions artists to organise Community Arts Projects (CAPs). Taking place in the months leading up to the festival, CAPs aim to engage groups of 10-20 seniors and encourage them to share their stories, express themselves, form new bonds and co-develop artistic content.",
    "category": 'Website',
    "company": 'IOIOlab'
  },
  "Estee-Lauder": {
    "title": "Estee Lauder Total Eye",
    "client": "Estee Lauder",
    "tech": "Unity 3D, Xamarin, Swift",
    "external_link": null,
    "showcase": [
      {content: '<div class="row" animate-me><div class="col-lg-9 mx-auto text-lg-center"><video class="detail-video" autoplay muted loop><source src=' + require("../assets/el_vid.mp4") +'></video></div></div>'},
    ],
    "gallery": [
      {id: 1, src: require("../assets/el_gallery1.png")},
      {id: 2, src: require("../assets/el_gallery2.jpg")}
    ],
    "thumbnail": require("../assets/el0.jpg"),
    "path": '/Project?title=Estee-Lauder',
    "body": "Estee Lauder Total Eye was a in-store deployment at Shanghai Airport. It consists of 3 different attraction areas, the iPad experience, on-screen video and touch screen panel interaction.\n\nMy boss and I went down to the store in Shanghai Airport to do the setup and testing so that everything would run smoothly on the day of the release.",
    "category": 'IPad and Unity Development',
    "company": 'IOIOlab'
  },
  "SIT-SAM": {
    "title": "SIT - Sitizen Ambassador",
    "client": "Singapore Institute of Technology",
    "tech": "PUG, Javascript, CSS & Sass, Drupal",
    "external_link": "http://sit-sam-html.s3-website-ap-southeast-1.amazonaws.com/",
    "showcase": [
      {content: '<div class="row align-items-center" animate-stagger><div class="col-lg-7" animate-item><img class="w-100 img-fluid" src=' + require("../assets/sit-sam1.png") +'></img></div><div class="col-lg-4 ml-auto" animate-item><span class="text-img-title">CMS Integration</span><span>The site is integrated with Drupal for our client to select which ambassadors they want to be featured on the landing page.</span></div></div>'},
    ],
    "gallery": [
      {id: 1, src: require("../assets/sit-sam0.png")}
    ],
    "thumbnail": require("../assets/sit-sam0.png"),
    "path": '/Project?title=SIT-SAM',
    "body": "SIT - Sitizen Ambassador is a project from the Singapore Institute of Technology(SIT) to create an ambassador website for their school ambassadors displaying the different featured ambassador's profiles and school events.",
    "category": 'Website',
    "company": 'EIGHTFOUR Digital'
  },
  "EDMs": {
    "title": "EDMs",
    "client": "Changi Air Group, Playpoint Asia",
    "tech": "HTML, Vanilla CSS, Mailchimp",
    "external_link": null,
    "showcase": [
      {content: '<div class="row align-items-center" animate-stagger><div class="col-lg-7" animate-item><img class="w-100 img-fluid" src=' + require("../assets/cag-edm2.png") +'></img></div><div class="col-lg-4 ml-auto" animate-item><span class="text-img-title">Editable components</span><span>The frontend team has created variations of editable components so that the client is able to choose which variation they like in mailchimp.</span></div></div>'},
    ],
    "gallery": [
      {id: 1, src: require("../assets/cag-edm1.png")},
      {id: 2, src: require("../assets/playpoint-edm1.png")}
    ],
    "thumbnail": require("../assets/cag-edm1.png"),
    "path": '/Project?title=EDMs',
    "body": "I had the privelege to do up multiple EDMs for various campaigns for different companies such as Changi Air Group and Playpoint Asia.\n\nThese EDM templates are done up with dynamic values for mailchimp so that our clients can set which texts and images they want to be replacable whenever they want to blast a new month's campaign.",
    "category": 'Electronic Direct Mail',
    "company": 'EIGHTFOUR Digital'
  },
  "Creature-Crew": {
    "title": "Creature Crew",
    "client": "City of Tomorrow - Singapore Zoo",
    "tech": "VueJS, Javascript, CSS & Sass",
    "external_link": "https://ioiolab.com/project/cot-creature-crew/dist/#/",
    "showcase": [
      {content: '<div class="row align-items-center" animate-stagger><div class="col-lg-7" animate-item><img class="w-100 img-fluid" src=' + require("../assets/cot-1.png") +'></img></div><div class="col-lg-4 ml-auto" animate-item><span class="text-img-title">Device checking</span><span>If the user is viewing this site using their desktop, which is highly unlikely. They will have to bypass this modal prepared just in case of such situations.</span></div></div>'},
    ],
    "gallery": [
      {id: 1, src: require("../assets/cot-2.jpg")},
      {id: 2, src: require("../assets/cot-3.jpg")},
      {id: 3, src: require("../assets/cot-4.jpg")},
      {id: 4, src: require("../assets/cot-5.jpg")}
    ],
    "thumbnail": require("../assets/cot-0.png"),
    "path": '/Project?title=Creature-Crew',
    "body": "Creature Crew is an website developed for mobile viewing, this site can only be viewed by scanning the QR code at the onsite event at the Singapore Zoo. Visitors at the zoo would find various QR code stands with their mascot animal for them to experience the whole journey with each QR code leading them to a different page on the site.",
    "category": 'Mobile Web App',
    "company": 'IOIOlab'
  }
};


<template>
  <Navbar/>
  <!-- <transition name="scale" mode="out-in">
    <router-view :key="$route.fullPath"/>
  </transition> -->
  <router-view v-slot="{Component, route}">
    <transition name="scale" mode="out-in">
      <div :key="route.fullPath">
        <component :is="Component" ></component>
      </div>
    </transition>
  </router-view>
  <Lightbox/>
  <RotatePrompt/>
  <Footer/>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import Lightbox from '@/components/Lightbox.vue';
import RotatePrompt from '@/components/RotatePrompt.vue';
import Func from '@/helpers/Function';
import { ref, onMounted, reactive, computed, onUpdated } from 'vue';
import { useRoute } from 'vue-router';

export default {
  components: {
    Navbar: Navbar, 
    Footer: Footer,
    Lightbox: Lightbox,
    RotatePrompt: RotatePrompt
  },
  setup(props, context) {

    onMounted(()=>{
      Func.setupMenu();
    });

    return {
      Navbar,
      Footer,
      Lightbox,
      RotatePrompt
    }
  }
}

</script>

<style lang="scss">
.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}
.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>

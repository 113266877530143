import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import Data from '@/helpers/Data';
//import { useStore } from 'vuex';
// import store from './stores';

const routes = [
  {
    path: '/',
    name: 'landing',
    component: () => import('./views/Landing.vue'),
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('./views/Projects.vue'),
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('./views/Project.vue'),
  },
  {
    path: '/research',
    name: 'research',
    component: () => import('./views/Research.vue'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('./views/Error.vue'),
  },
];



const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      console.log(to.hash);
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }
  },
});

router.beforeEach((to, from) => {
  if(to.name != 'project' && to.name != 'projects' && to.name != 'research' && to.name != 'landing' && to.name != 'error'){
    return {
      path: '/error',
      query: { redirect: to.fullPath },
    }
  }
  if (to.name == 'project') {
    var projName = to.query.title;
    if(Data[projName] == undefined) {
      return {
        path: '/error',
        // save the location we were at to come back later
        query: { redirect: to.fullPath },
      }
    }
  }
  window.scrollTo(0, 0);
})

export default router;

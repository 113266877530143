<template>
  <div class="prompt-rotate hide">
    <img src="@/assets/promptRotateScreen.png" alt="rotateScreen"/>
    <span>Please rotate your device to see this page</span>
  </div>
</template>

<script>

import { computed, onMounted } from 'vue';

export default {
  setup() {
    onMounted(()=> {
      // console.log('rotate prompt mounted ');
    })

    return {
    }
  }
  
}
</script>
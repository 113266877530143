import { createApp } from 'vue'
import App from './App.vue'
import VueTypedJs from 'vue-typed-js'
import Equal from 'equal-vue'
import {VueMasonryPlugin} from 'vue-masonry';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faArrowRight, faTimes, faSun, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebookF, faTwitter, faWhatsapp, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import store from './stores';
import Lottie from "vue-lottie";
import router from './router';
import '@/scripts/scrollPosStyler';
import 'equal-vue/dist/style.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/common.scss";

library.add(faUserSecret, faSun, faArrowRight, faTimes, faInstagram, faWhatsapp, faFacebookF, faTwitter, faLinkedinIn, faEnvelope);

createApp(App)
.use(Equal)
.use(VueTypedJs)
.use(VueMasonryPlugin)
.use(store)
.use(router)
.component("lottie", Lottie)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
<template>
  <div class="lightbox hide">
    <div class="modal-content">
      <!-- for medias - photos/ videos? -->
      <div class="content-holder text-center">
        <!-- <a class="close-video"><font-awesome-icon :icon="['fa', 'times']"></font-awesome-icon></a> -->
        <span>This page is currently undergoing revamp...</span>
        <span>check out the live/archived version below or head back to the home page</span>
        <!-- <iframe allowfullscreen class="video-frame" src="https://www.youtube.com/embed/V0XUd8f2pz8"></iframe> -->
        <a data-link target="_blank" class="mt-4" href=""></a>
      </div>
      <!-- <nav>
            <a href="#" class="left"></a>
            <a href="#" class="right"></a>
      </nav> -->
    </div>
  </div>
</template>

<script>

import { computed, onMounted } from 'vue';

export default {
  setup() {
    onMounted(()=> {
      // lightbox functions
      // $(".lightbox").unbind().on("click", function(e){
      //   e.preventDefault();
      //   $(".lightbox").removeClass('show');
      //   $(".lightbox").addClass('hide');
      //   $("body").removeClass("noscroll");
      //   $('iframe').attr('src', '');
      // });
    })

    return {

    }
  }
  
}
</script>
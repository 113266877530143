<template>
  <div class="top-nav-bar sps sps--abv">
    <div class="header-inner">
      <div class="lefthand">
        <a class="nav-logo" href="#">
          <img src="../assets/logo.png" alt="logo"/>
        </a>
        <a class="lightToDark" href="#">
          <div></div>
        </a>
      </div>
      <div class="nav-bar-buttons">
        <a href="#" class="nav-item"><span>Contact me</span></a>
      </div>

    </div>
  </div>
</template>

<script>

import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const mode = ref('dark');

    onMounted(()=>{

      $(".nav-item").on("click", function(e) {
        e.preventDefault();
        var offset = $(".footer").offset().top;
        window.scrollTo({top: offset, behavior: 'smooth'});
      });

      $(".lightToDark").on("click", function(e) {
        e.preventDefault();
        if(mode.value == 'dark') {
          mode.value = 'Light';
          store.dispatch("account/setDark");
          $("body").addClass('darkmode');
          $(".lightToDark").addClass('active');
          $(".footer").addClass('darkmode');
          $(".top-nav-bar").addClass('darkmode');
          $(".site-index").addClass('darkmode');
          $(".site-project").addClass('darkmode');
          $(".site-projects").addClass('darkmode');
          $(".site-research").addClass('darkmode');
          $(".site-error").addClass('darkmode');
        } else {
          mode.value = 'dark';
          store.dispatch("account/setLight");
          $("body").removeClass('darkmode');
          $(".lightToDark").removeClass('active');
          $(".footer").removeClass('darkmode');
          $(".top-nav-bar").removeClass('darkmode');
          $(".site-index").removeClass('darkmode');
          $(".site-project").removeClass('darkmode');
          $(".site-projects").removeClass('darkmode');
          $(".site-research").removeClass('darkmode');
          $(".site-error").removeClass('darkmode');
        }
      });
    });

    function scrollTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return {
      scrollTop,
      mode
    }
  }
  
}
</script>